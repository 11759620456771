<template>
  <div class="framework-container">
    <div class="framework-head flex flex-align-center">
      <img
        class="framework-title-icon"
        src="~@/assets/u8/icon-title.png"
        alt=""
      />
      <span class="framework-title-text">发展规模</span>
    </div>
    <div class="name" v-for="(item, index) in nameList" :class="'name' + index">
      {{ item }}
    </div>
    <div
      class="project"
      v-for="(item, index) in projectList"
      :class="'project' + index"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nameList: [
        "黑龙江",
        "河北",
        "江苏",
        "浙江",
        "湖南",
        "贵州",
        "四川",
        "云南",
      ],
      projectList: [
        "2019年已覆盖5个省",
        "国家现代化服务试点项目",
        "2020年游巴网将覆盖8个省",
        "2019年已覆盖5个省",
        "科技部智慧交通综合服务试点项目",
        "浙江省互联网+旅游客运改革重点项目",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.framework-container {
  width: 100%;
  height: 800px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/map-bg.png")
    no-repeat;
  box-sizing: border-box;
  background-size: 100% 100%;
  position: relative;
  padding: 40px 265px 91px 240px;

  .framework-head {
    margin-bottom: 149px;

    .framework-title-icon {
      margin-right: 10px;
    }

    .framework-title-text {
      font-size: 36px;
      color: #fff;
    }
  }

  .name {
    color: #fff;
    animation: nameScale 3s linear infinite;
  }

  //地名大小变化
  @keyframes nameScale {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0.5);
    }
    40% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.5);
    }
    80% {
      transform: scale(2);
    }
    100% {
      transform: scale(2.5);
    }
  }
  //地名定位
  .name0 {
    animation-delay: 0.4s;
    position: absolute;
    right: 570px;
    top: 200px;
  }

  .name1 {
    animation-delay: 0.8s;
    position: absolute;
    right: 780px;
    top: 320px;
  }

  .name2 {
    animation-delay: 1s;
    position: absolute;
    right: 700px;
    top: 400px;
  }

  .name3 {
    animation-delay: 0.9s;
    position: absolute;
    right: 700px;
    top: 450px;
  }

  .name4 {
    animation-delay: 0.3s;
    position: absolute;
    right: 840px;
    top: 460px;
  }

  .name5 {
    animation-delay: 0.7s;
    position: absolute;
    right: 920px;
    top: 480px;
  }

  .name6 {
    animation-delay: 0.2s;
    position: absolute;
    right: 990px;
    top: 430px;
  }

  .name7 {
    animation-delay: 0.6s;
    position: absolute;
    right: 1000px;
    top: 510px;
  }

  .project {
    box-sizing: border-box;
    padding: 10px 30px;
    display: inline-block;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    border: 1px solid rgba(255, 255, 255, 1);
  }

  //  动画初始定位
  .project0 {
    position: absolute;
    left: 580px;
    top: 240px;
    animation: fromLeft 3s linear infinite;
  }

  .project1 {
    position: absolute;
    left: 270px;
    top: 420px;
    animation: fromLeft 3s linear infinite;
  }

  .project2 {
    position: absolute;
    left: 540px;
    top: 570px;
    animation: fromLeft 3s linear infinite;
  }

  .project3 {
    position: absolute;
    right: 430px;
    top: 300px;
    animation: fromRight 4s linear infinite;
  }

  .project4 {
    position: absolute;
    right: 260px;
    top: 510px;
    animation: fromRight 4s linear infinite;
  }

  .project5 {
    position: absolute;
    right: 590px;
    bottom: 80px;
    animation: fromRight 4s linear infinite;
  }

  //左侧动画
  @keyframes fromLeft {
    0% {
      opacity: 0;
      transform: translateX(-600px);
    }
    60% {
      opacity: 1;
      transform: translateX(10px);
    }
    80% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  //右侧动画
  @-webkit-keyframes fromRight {
    0% {
      opacity: 0;
      transform: translateX(600px);
    }
    60% {
      opacity: 1;
      transform: translateX(-30px);
    }
    80% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
