<template>
  <div class="advantage-container">
    <div class="title">
      平台特点
    </div>
    <div class="flex">
      <div class="list flex flex-direction-column">
        <template v-for="(item,index) in list1">
          <img class="item" :class="'item' + (index + 1)" :src="item" alt=""/>
        </template>
      </div>
      <!--      <img class="middle-img" src="~@/assets/index/tedian4.png" alt="">-->
      <div class="list2 flex flex-direction-column">
        <template v-for="(item,index) in list2">
          <img class="item" :class="'item' + (index + 4)" :src="item" alt=""/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list1: [
        require("@/assets/index/tedian1.png"),
        require("@/assets/index/tedian2.png"),
        require("@/assets/index/tedian3.png"),
      ],
      list2: [
        require("@/assets/index/tedian5.png"),
        require("@/assets/index/tedian6.png"),
        require("@/assets/index/tedian7.png"),
      ],

    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 740px;
  background: url("~@/assets/index/tedian4.png") no-repeat;
  background-position: center 60%;
  box-sizing: border-box;
  padding: 55px 180px 100px;
  position: relative;

  .title {
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    margin-bottom: 90px;
  }

  .list {
    .item {
      //margin-bottom: 48px;
    }

    .item1 {
      position: absolute;
      top: 170px;
      left: 240px;
    }

    .item2 {
      position: absolute;
      top: 340px;
      left: 190px;
    }

    .item3 {
      position: absolute;
      top: 510px;
      left: 240px;
    }
  }

  .middle-img {
    width: 556px;
    height: 431px;
    left: 50%;
    margin-left: 278px;
  }

  .list2 {
    .item {
      //margin-bottom: 48px;
    }

    .item4 {
      position: absolute;
      top: 170px;
      right: 240px;
    }

    .item5 {
      position: absolute;
      top: 340px;
      right: 190px;
    }

    .item6 {
      position: absolute;
      top: 510px;
      right: 240px;
    }
  }


}
</style>
