<template>
  <div class="advantage-container">
    <div class="title">
      数智时代包车客运痛点
    </div>
    <div class="list flex flex-between">
      <template v-for="(item,index) in list">
        <img :class="'item' + (index + 1)" :src="item" alt=""/>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        require("@/assets/index/tongdian1.png"),
        require("@/assets/index/tongdian2.png"),
        require("@/assets/index/tongdian3.png"),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  //height: 700px;
  //background: url("~@/assets/u8/advantage-bg.png") no-repeat;
  background: #fff;
  box-sizing: border-box;
  background-size: contain;
  padding: 55px 300px;

  .title {
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    margin-bottom: 90px;
  }

  .list {

  }

}
</style>
