<template>
  <div class="advantage-container">
    <div class="title">
      提升服务质量：企业服务的四大优势
    </div>
    <img class="bg" src="~@/assets/index/fuwu-bg.png" alt=""/>
    <div class="list flex">
      <div class="item" v-for="(item,index) in list" :class="index == active ? 'active' : '' "
           @mouseover="test(item,index)">
        <div class="item-top flex">
          <div class="item-num">{{ item.num }}</div>
          <div class="item-title">{{ item.title }}</div>
        </div>
        <div class="item-content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      list: [
        {num: "01", title: "提升企业管理", content: '解决企业管理成本高，管理方式落后问题'},
        {num: "02", title: "多功能系统", content: '提供企业信息数字化的订单管理、财务管理、车辆管理、人员管理等功能系统'},
        {num: "03", title: "多渠道对接", content: '对接多渠道订车窗口，提升企业业务实现企业增效'},
        {num: "04", title: "数字化调动", content: '解决车辆淡旺季空置率闲置率、空载率高的问题'},
      ]
    }
  },
  methods: {
    test(item, index) {
      this.active = index
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 530px;
  background: #F5F7FB;
  box-sizing: border-box;
  position: relative;

  .title {
    width: 780px;
    font-size: 48px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 56px;
    position: absolute;
    left: 588px;
    top: 113px;
  }

  .list {
    position: absolute;
    top: 230px;
    left: 210px;

    .item {
      width: 375px;
      height: 230px;
      background: #FFFFFF;
      box-sizing: border-box;
      padding: 20px;

      .item-top {
        margin-bottom: 11px;

        .item-num {
          font-size: 48px;
          font-weight: bold;
          color: #333333;
          line-height: 56px;
          margin-right: 20px;
        }

        .item-title {
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          line-height: 56px;
        }
      }

      .item-content {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 38px;
        text-align: left;
      }

      &.active {
        background: url("~@/assets/index/fuwu-item-hover.png") no-repeat;
        background-size: 100% 100%;

        .item-num {
          color: #fff;
        }

        .item-title {
          color: #fff;
        }

        .item-content {
          color: #fff;
        }
      }
    }


  }

}
</style>
