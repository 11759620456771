<template>
  <div class="container">

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.container{
  width: 1920px;
  height: 1112px;
  background: url("~@/assets/index/baoche5.png") no-repeat;
  background-size: 100% 100%;
}
</style>
