<template>
  <div class="digital-regulation-container">
    <div class="digital-regulation-head flex flex-align-center">
      <img
        class="digital-regulation-title-icon"
        src="~@/assets/u8/icon-title.png"
        alt=""
      />
      <span class="digital-regulation-title-text">监督平台</span>
    </div>

    <div
      class="text text1 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinL' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation2.png" alt="" />
      <span>车牌管理</span>
    </div>
    <div
      class="text text2 fontFff flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinL' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation1.png" alt="" />
      <span>运营车辆</span>
    </div>

    <div
      class="text text3 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinL' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation3.png" alt="" />
      <span>运行轨迹</span>
    </div>
    <div
      class="text text4 fontFff flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinL' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation4.png" alt="" />
      <span>电子围栏</span>
    </div>
    <div
      class="text text5 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinL' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation6.png" alt="" />
      <span>疲劳驾驶</span>
    </div>

    <!--    <div class="text text6 fontFff flex flex-center flex-align" :class="startAnimation ? 'a-fadeinL' : ''"-->
    <!--         v-if="startAnimation">-->
    <!--      <img src="~@/assets/u8/digital-regulation5.png" alt=""/>-->
    <!--      <span>车辆违章</span>-->
    <!--    </div>-->

    <div
      class="text text7 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinR' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation7.png" alt="" />
      <span>客运企业</span>
    </div>
    <div
      class="text text8 fontFff flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinR' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation8.png" alt="" />
      <span>驾驶人员</span>
    </div>

    <div
      class="text text9 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinR' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation9.png" alt="" />
      <span>信用体系</span>
    </div>
    <div
      class="text text10 fontFff flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinR' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation10.png" alt="" />
      <span>大数据</span>
    </div>
    <div
      class="text text11 fontBlue flex flex-center flex-align"
      :class="startAnimation ? 'a-fadeinR' : ''"
      v-if="startAnimation"
    >
      <img src="~@/assets/u8/digital-regulation11.png" alt="" />
      <span>履约信息</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll: 0,
      startAnimation: false, //文字是否出现
    };
  },
  methods: {
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.startAnimation = this.scroll > 600;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.digital-regulation-container {
  width: 1920px;
  height: 1080px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/digital-regulation-bg.png")
    no-repeat;
  box-sizing: border-box;
  background-size: 100% 100%;
  padding: 70px 270px 170px;
  color: #333;
  position: relative;

  .digital-regulation-head {
    margin-bottom: 130px;

    .digital-regulation-title-icon {
      margin-right: 10px;
    }

    .digital-regulation-title-text {
      font-size: 36px;
    }
  }

  .text {
    width: 222px;
    height: 76px;
    box-shadow: 0px 2px 8px 3px rgba(6, 107, 241, 0.06);
    border-radius: 8px;
    opacity: 1;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 22px;
    }
  }

  .text1 {
    position: absolute;
    top: 299px;
    left: 399px;
  }

  .text2 {
    position: absolute;
    top: 405px;
    left: 399px;
  }

  .text3 {
    position: absolute;
    top: 604px;
    left: 221px;
  }
  .text4 {
    position: absolute;
    top: 710px;
    left: 221px;
  }
  .text5 {
    position: absolute;
    top: 816px;
    left: 221px;
  }

  .text7 {
    position: absolute;
    top: 299px;
    right: 399px;
  }

  .text8 {
    position: absolute;
    top: 405px;
    right: 399px;
  }

  .text9 {
    position: absolute;
    top: 604px;
    right: 220px;
  }

  .text10 {
    position: absolute;
    top: 710px;
    right: 220px;
  }

  .text11 {
    position: absolute;
    top: 816px;
    right: 220px;
  }

  .fontFff {
    color: #333;
    background: #ffffff;
  }

  .fontBlue {
    background: linear-gradient(45deg, #217eff 0%, #124fed 100%);
    color: #fff;
  }

  .a-fadeinL {
    animation: fadeinL 1.5s ease-out backwards;
  }

  .a-fadeinR {
    animation: fadeinR 1.5s ease-out backwards;
  }
}
</style>
