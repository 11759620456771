<template>
  <div class="advantage-container">
    <div class="advantage-head flex flex-align-center">
      <img class="advantage-title-icon" src="~@/assets/u8/icon-title.png" alt=""/>
      <span class="advantage-title-text">平台优势</span>
    </div>
    <div class="list flex flex-between">
      <template v-for="(item,index) in list">
        <img :class="'item' + (index + 1)" :src="item" alt="">
      </template>
    </div>
    <img class="bottom-bg" src="~@/assets/u8/advantage-bottom-bg.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        require("@/assets/u8/advantage-item1.png"),
        require("@/assets/u8/advantage-item2.png"),
        require("@/assets/u8/advantage-item3.png"),
        require("@/assets/u8/advantage-item4.png"),
        require("@/assets/u8/advantage-item5.png"),
        require("@/assets/u8/advantage-item6.png"),
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 980px;
  //background: url("~@/assets/u8/advantage-bg.png") no-repeat;
  background: #fff;
  box-sizing: border-box;
  background-size: contain;
  padding: 40px 265px 91px 240px;
  color: #333;

  .advantage-head {
    margin-bottom: 149px;

    .advantage-title-icon {
      margin-right: 10px;
    }

    .advantage-title-text {
      font-size: 36px;
    }
  }

  .list {
    margin-bottom: 100px;

    .item1, .item3, .item5 {
      position: relative;
      top: 60px;
    }

    .item1:hover {
      content: url("~@/assets/u8/advantage-item1-active.png");
    }

    .item2:hover {
      content: url("~@/assets/u8/advantage-item2-active.png");
    }

    .item3:hover {
      content: url("~@/assets/u8/advantage-item3-active.png");
    }

    .item4:hover {
      content: url("~@/assets/u8/advantage-item4-active.png");
    }

    .item5:hover {
      content: url("~@/assets/u8/advantage-item5-active.png");
    }

    .item6:hover {
      content: url("~@/assets/u8/advantage-item6-active.png");
    }

    img {
      width: 180px;
      height: 240px;
    }

  }

  .bottom-bg {
    width: 100%;
  }
}
</style>
