<template>
  <div class="introduce-container">
    <div class="introduce-head flex flex-align-center">
      <img
        class="introduce-title-icon"
        src="~@/assets/u8/icon-title.png"
        alt=""
      />
      <span class="introduce-title-text">游巴介绍</span>
    </div>
    <div class="article">
      中国游巴网（www.chinaU8.cn）是全国首家数字化、智能化的旅游客运行业监管服务平台。中国游巴网从客运企业、车辆、驾驶员等静态信息合规性管控入手，集订单交易、
      包车牌审核、GPS跟踪、交警路查、安全台帐、安全培训、风险预警及网上执法于一体，是国内首个实现旅游车源100%合法的平台，也是率先实现运管、交通、旅游、教育、应急等多部门动态监管、信息共享的平台。中国游巴网自2014年3月创立以来，已陆续在7个省市与当地政府洽谈合作或布署，为当地政府、企事业单位、学校及广大社会公众提供旅游用车的安全监管服务达720余万人次，受到社会大众的广泛赞誉与好评。
    </div>
    <div class="video">
      <PlayerVideo
        src="https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/3599dfd8536d5d726704f3ba8355d6ca.mp4"
      ></PlayerVideo>
    </div>
  </div>
</template>

<script>
import PlayerVideo from "@/components/video/playerVideo.vue";

export default {
  components: {
    PlayerVideo,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.introduce-container {
  width: 1920px;
  height: 1080px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/introduce-bg.png")
    no-repeat;
  box-sizing: border-box;
  background-size: cover;
  padding: 140px 260px 64px 240px;
  color: #333;

  .introduce-head {
    margin-bottom: 35px;

    .introduce-title-icon {
      margin-right: 10px;
    }

    .introduce-title-text {
      font-size: 36px;
    }
  }

  .article {
    text-indent: 2em;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
    margin-bottom: 34px;
  }

  .video {
    width: 1148px;
    height: 642px;
    margin: 0 auto;
  }
}
</style>
