<template>
  <div class="business-ecology-container">
    <div class="business-ecology-head flex flex-align-center">
      <img
        class="business-ecology-title-icon"
        src="~@/assets/u8/icon-title.png"
        alt=""
      />
      <span class="business-ecology-title-text">业务生态</span>
    </div>
    <div class="img-float flex flex-between flex-align-center">
      <template v-for="(item, index) in list">
        <div class="flex flex-direction-column" :class="'item' + (index + 1)">
          <img :src="item.path" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </template>
    </div>
    <div class="img-float2 flex flex-between flex-align-center">
      <template v-for="(item, index) in list2">
        <div class="flex flex-direction-column" :class="'item' + (index + 1)">
          <img :src="item.path" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "一车一码",
          path: require("@/assets/u8/business-ecology-icon1-new.png"),
        },
        {
          name: "旅游定制",
          path: require("@/assets/u8/business-ecology-icon3.png"),
        },
        {
          name: "校园通",
          path: require("@/assets/u8/business-ecology-icon5.png"),
        },
      ],
      list2: [
        {
          name: "包车客运",
          path: require("@/assets/u8/business-ecology-icon2-new.png"),
        },
        {
          name: "安全监管",
          path: require("@/assets/u8/business-ecology-icon4.png"),
        },
        {
          name: "电子路单",
          path: require("@/assets/u8/business-ecology-icon6.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.business-ecology-container {
  width: 1920px;
  height: 1080px;
  box-sizing: border-box;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/business-ecology-bg.png")
    no-repeat;
  background-size: contain;
  padding: 40px 260px 64px 240px;
  color: #333;

  .business-ecology-head {
    .business-ecology-title-icon {
      margin-right: 10px;
    }

    .business-ecology-title-text {
      font-size: 36px;
    }
  }

  .img-float {
    position: relative;
    left: 140px;
    top: 340px;
    width: 900px;
    font-size: 20px;

    img {
      width: 105px;
      margin-bottom: 10px;
    }

    .item1 {
      animation: float 3s ease-in-out infinite;
      animation-delay: 1s;
    }

    .item2 {
      position: relative;
      top: -20px;
      animation: float 3s ease-in-out infinite;
      animation-delay: 1.5s;
    }

    .item3 {
      position: relative;
      top: -10px;
      left: 80px;
      animation: float 3s ease-in-out infinite;
      animation-delay: 1.8s;
    }
  }

  .img-float2 {
    position: relative;
    left: 190px;
    top: 20px;
    width: 900px;
    font-size: 20px;

    img {
      width: 105px;
      margin-bottom: 10px;
    }

    .item1 {
      animation: float 3s ease-in-out infinite;
      animation-delay: 1.2s;
      position: relative;
      left: 140px;
    }

    .item2 {
      position: relative;
      top: 80px;
      left: 200px;
      animation: float 3s ease-in-out infinite;
      animation-delay: 1.6s;
    }

    .item3 {
      position: relative;
      top: 40px;
      left: 240px;
      animation: float 3s ease-in-out infinite;
      animation-delay: 2s;
    }
  }

  @keyframes float {
    from {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50px);
    }
    to {
      transform: translateY(0);
    }
  }
}
</style>
