<template>
  <div class="system-architecture-container">
    <div class="title">系统架构</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.system-architecture-container {
  width: 1920px;
  height: 1303px;
  padding: 93px;
  box-sizing: border-box;
  background: url("~@/assets/homepage/system-bg.png") no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 36px;
    font-weight: 500;
    color: #000000;
    line-height: 42px;
  }
}
</style>
