<template>
  <div class="culture_video" ref="box">
    <div class="video_background">
      <div class="shade">
        <div class="box" ref="box">
          <video
            ref="video"
            class="video-js vjs-big-play-centered vjs-show-big-play-button-on-pause"
            controls
          >
            <source :src="src" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "playerVideo",
  props: {
    src: {
      type: String,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    muted: {
      type: Boolean,
      default: false,
    },
    volumePanel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      player: null,
      width: "1148px",
      height: "642px",
      playerOptions: {
        poster:
          "https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/video-cover.png",
        // videojs options
        autoplay: false,
        muted: this.muted,
        // preload: "auto",
        language: "zh-CN",
        fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            // type: 'video/mp4',
            type: "video/mp4",
            src: this.src, // 路径
            // src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
          },
        ],
        // poster: 'https://t7.baidu.com/it/u=602106375,407124525&fm=193&f=GIF', // 你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
          currentTimeDisplay: true, // 当前时间
          volumeControl: false, // 声音控制键
          volumePanel: this.volumePanel,
          playToggle: true, // 暂停和播放键
          progressControl: true, // 进度条
        },
      },
    };
  },
  created() {},
  mounted() {
    let videoWidth = this.$refs.box.offsetWidth;
    let videoHeight = this.$refs.box.offsetHeight;
    this.playerOptions.aspectRatio = videoWidth + ":" + videoHeight;
    this.player = this.$video(this.$refs.video, this.playerOptions);
    //添加视频播放监听事件
    this.player.on("play", () => {
      this.handlePlay();
    });
    //添加视频播放暂停事件   组件销毁好像自带暂停所以不需要在beforeDestroy执行修改背景音乐方法???
    this.player.on("pause", () => {
      this.handlePause();
    });
  },
};
</script>

<style scoped lang="scss">
.culture_video {
  width: 100%;
  height: 642px;
  border-radius: 4px;
  //box-shadow: 0px 0px 10px 0px rgba(40, 115, 87, 30);
  border: 3px solid rgba(255, 255, 255, 1);
  margin-top: 20px;

  .video_background {
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .video_background::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    filter: blur(10px);
    //background-image: url(../../assets/images/culture_video_background.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .shade {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100; /* 此处的图层要大于页面 */
    background: rgba(255, 255, 255, 1);
  }
}

.box {
  width: 1148px;
  height: 642px;
  margin: auto;
  overflow: hidden;
}

video {
  width: 100% !important;
  height: 100% !important;
  //   max-height: calc(85vh - 336px) !important;
  object-fit: fill; //消除留白
}

.video-js.vjs-fluid {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
  display: block;
}

.video-js.vjs-playing .vjs-tech {
  pointer-events: auto;
}
</style>
