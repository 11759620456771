<template>
  <div class="advantage-container">
    <div class="title">
      包车客运综合服务平台
    </div>
    <div class="info-box">
      <div class="text">
        中国游巴网是一款全面升级的包车客运综合性服务平台，涵盖行业监管端、企业管理端和社会营运端，
        致力于为我国包车客运行业提供便捷、高效、安全的服务。我们以创新科技为核心，结合行业需求，
        实现信息共享，资源优化配置，提升行业竞争力，让包车出行更轻松、更愉快
      </div>
      <div class="flex">
        <div>
          <img src="~@/assets/index/baoche1.png" alt=""/>
          <img src="~@/assets/index/baoche2.png" alt=""/>
        </div>
        <div>
          <img src="~@/assets/index/baoche3.png" alt=""/>
        </div>
        <div class="img-right">
          <img src="~@/assets/index/baoche4.png" alt=""/>
        </div>
      </div>
    </div>
<!--    <img class="img-bottom" src="~@/assets/index/baoche5.png" alt="">-->
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 900px;
  background: #F5F5FF;
  box-sizing: border-box;
  padding: 40px 185px;
  position: relative;

  .title {
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    margin-bottom: 20px;
  }

  .info-box {
    width: 1546px;
    height: 700px;
    background: #FCFBFF;
    box-sizing: border-box;
    padding: 40px 125px 70px;
    margin-bottom: 70px;

    .text {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      line-height: 42px;
      text-align: left;
      text-indent: 2em;
      margin-bottom: 60px;
    }

    .img-right {
      align-self: end;
      margin-left: 30px;
    }
  }
  .img-bottom{
    position: absolute;
    left: 0;
  }

}
</style>
