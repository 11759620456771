<template>
  <div class="advantage-container">
    <div class="title">
      优化用户体验：社会营运四大亮点
    </div>
    <div class="list flex flex-between">
      <div class="item" v-for="item in list">
        <img class="item-icon" :src="item.icon" alt=""/>
        <div class="item-name">{{ item.name }}</div>
        <div class="item-content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "订车便捷",
          content: "移动端、PC端，公众号、小程序、多渠道提供订车窗口",
          icon: require("@/assets/index/tiyan1.png"),
        },
        {
          name: "价格透明",
          content: "平台汇聚合法合规 车企、车辆、形成健康客运用车生态",
          icon: require("@/assets/index/tiyan2.png"),
        },
        {
          name: "用车安全",
          content: "车辆严格监管、出行实时监测、保用车安全性",
          icon: require("@/assets/index/tiyan3.png"),
        },
        {
          name: "可评可诉",
          content: "完善的用车评价体系，保障用车过程中的服务质量",
          icon: require("@/assets/index/tiyan4.png"),
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 696px;
  background: #fff;
  box-sizing: border-box;
  padding: 37px 188px 100px;

  .title {
    font-size: 48px;
    font-weight: bold;
    color: #333333;
    line-height: 56px;
    margin-bottom: 53px;
  }

  .list {
    .item {
      width: 336px;
      height: 433px;
      background: #F8FBFF;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 36px 44px;

      .item-icon {

      }

      .item-name {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 42px;
        margin-bottom: 32px;
      }

      .item-content {
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        text-align: left;
      }
    }
  }


}
</style>
