<template>
  <div class="advantage-container">
    <div class="title">
      全方面保障：行业监管六大优势
    </div>
    <div class="list flex flex-wrap flex-between">
      <div class="item" v-for="item in list">
        <div class="item-top flex flex-between flex-align-center">
          <div class="item-top-left">
            <div class="item-title">{{ item.name }}</div>
            <img src="~@/assets/index/youshi-line.png" alt=""/>
          </div>
          <div class="item-right-icon">
            <img :src="item.icon" alt=""/>
          </div>
        </div>
        <div class="item-content">
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "监管执法",
          content: "系统实时追踪车辆运行，确保合规运营，精准执法，维护市场行业秩序。",
          icon: require("@/assets/index/youshi1.png"),
        },
        {
          name: "责任主体落实",
          content: "明确企业、驾驶员责任，强化安全意识，保障乘客权益。",
          icon: require("@/assets/index/youshi2.png"),
        },
        {
          name: "溯源追查",
          content: "全程记录车辆行驶轨迹，一旦发生问题，迅速追溯到根源。",
          icon: require("@/assets/index/youshi3.png"),
        },
        {
          name: "行车动态监控",
          content: "实时掌握车辆速度、位置等信息，预警异常情况，防患于未然。",
          icon: require("@/assets/index/youshi4.png"),
        },
        {
          name: "提前预警",
          content: "系统智能分析，预测可能出现的安全隐患，提前发出预警，降低风险。",
          icon: require("@/assets/index/youshi5.png"),
        },
        {
          name: "应急处理",
          content: "面对突发事件，系统迅速启动应急响应，协助相关部门，确保乘客安全。",
          icon: require("@/assets/index/youshi6.png"),
        },

      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.advantage-container {
  width: 1920px;
  height: 794px;
  background: #fff;
  box-sizing: border-box;
  background-size: contain;
  padding: 47px 180px 60px;

  .title {
    font-size: 48px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    margin-bottom: 57px;
  }

  .list {
    .item {
      width: 452px;
      height: 255px;
      background: #FFFFFF;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      box-sizing: border-box;
      padding: 14px 28px;
      margin-bottom: 43px;

      .item-top {
        margin-bottom: 10px;

        .item-top-left {
          text-align: left;

          .item-title {
            font-size: 32px;
            font-weight: 500;
            color: #333333;
            line-height: 38px;
            margin-bottom: 13px;
          }
        }

        .item-right-icon {

        }
      }

      .item-content {
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        color: #666666;
        line-height: 38px;
        text-indent: 2em;
      }
    }
  }

}
</style>
