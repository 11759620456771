<template>
  <div class="">
    <!--头部-->
    <div class="banner">
      <swiper :options="swiperOption" ref="mySwiper" style="min-width:1200px">
        <swiper-slide class="swiper-slide swiper-container-3d" v-for="(banner,index) in bannerList" :key="index">
          <img class="banner-img" :src="banner"/>
          <!--          <el-button class="contact-button a-bounceinL" @click="showDialog">联系我们</el-button>-->
        </swiper-slide>
      </swiper>
      <div class="title1 a-bounceinT">
        安全包车，畅享出行
      </div>
      <div class="title2 a-bounceinL">
        <div>打造包车客运综合性服务平台，致力于包车客运行业服务体系建设，专注于数字孪生可视化包车客运体系
        </div>
        <br/>
        聚焦智慧包车，推动包车客运行业智能化、标准化、规范化、集约化发展，以实现包车客运行业的健康、有序的运营环境。
      </div>
    </div>

    <Tongdian></Tongdian>
    <Baoche></Baoche>
    <Img></Img>
    <Tedian></Tedian>
    <Youshi></Youshi>
    <Fuwu></Fuwu>
    <Tiyan></Tiyan>
    <Introduce></Introduce>
    <!--    <div class="jianguan">-->
    <!--      <div class="name">包车客运数字监管</div>-->
    <!--      <div class="desc">-->
    <!--        数字监管全面监管客运企业、营运车辆、从业人员及包车牌，通过静态监管车辆及人员信息和动态监管车辆轨迹，达到多部门严格把关的“知人”、“知车”、“知去向”的监管要求，提供强大的数字护航服务。-->
    <!--      </div>-->
    <!--      <div class="jianguan_row">-->
    <!--        <div v-for="item in jianguanList" class="jianguan_box">-->
    <!--          <img :src="item.imgUrl"/>-->
    <!--          <div>{{ item.name }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="youba">-->
    <!--      <div class="name">平台优势</div>-->
    <!--    </div>-->

    <!--    数字监管-->
    <!--    <DigitalRegulation></DigitalRegulation>-->
    <!--    多平台协同-->
    <!--    <Cooperate></Cooperate>-->
    <!--    &lt;!&ndash;    数据架构&ndash;&gt;-->
    <!--    <div class="data">-->
    <!--      <div class="title">数据架构</div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash;    系统架构&ndash;&gt;-->
    <!--    <SystemArchitecture></SystemArchitecture>-->
    <!--    &lt;!&ndash;    游巴介绍&ndash;&gt;-->
    <!--    <Introduce></Introduce>-->
    <!--    &lt;!&ndash;    业务生态&ndash;&gt;-->
    <!--    <BusinessEcology></BusinessEcology>-->
    <!--    &lt;!&ndash;    平台优势&ndash;&gt;-->
    <!--    &lt;!&ndash;    <Advantage></Advantage>&ndash;&gt;-->
    <!--    &lt;!&ndash;    发展规模&ndash;&gt;-->
    <!--    <DevelopmentScale></DevelopmentScale>-->

  </div>
</template>

<script>
import BusinessEcology from "@/views/business-a/page-a/components/business-ecology.vue";
import Advantage from "@/views/business-a/page-a/components/advantage.vue";
import DevelopmentScale from "@/views/business-a/page-a/components/development-scale.vue";
import DigitalRegulation from "@/views/business-a/page-a/components/digital-regulation.vue";
import Cooperate from "@/views/business-a/page-a/components/cooperate.vue";
import SystemArchitecture from "@/views/business-a/page-a/components/system-architecture.vue";
// import Supervise from "@/views/business-a/page-a/components/supervise.vue";
// import SystemProject from "@/views/business-a/page-a/components/system-project.vue";
// import Framework1 from "@/views/business-a/page-a/components/framework1.vue";
// import Case from "@/views/business-a/page-a/components/case.vue";
import Tongdian from "@/views/business-a/page-a/components2/tongdian.vue";
import Baoche from "@/views/business-a/page-a/components2/baoche.vue";
import Tedian from "@/views/business-a/page-a/components2/tedian.vue";
import Youshi from "@/views/business-a/page-a/components2/youshi.vue";
import Img from "@/views/business-a/page-a/components2/img.vue"
import Fuwu from "@/views/business-a/page-a/components2/fuwu.vue";
import Tiyan from "@/views/business-a/page-a/components2/tiyan.vue";
import Introduce from "@/views/business-a/page-a/components/introduce.vue"


export default {
  name: "home",
  components: {
    // BusinessEcology,
    // Advantage,
    // DevelopmentScale,
    // DigitalRegulation,
    // Cooperate,
    // SystemArchitecture,
    Tongdian,
    Baoche,
    Tedian,
    Img,
    Youshi,
    Fuwu,
    Tiyan,
    Introduce,
  },
  computed: {},
  data() {
    return {
      bannerList: [
        // 'https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/index-banner1.png',
        'https://imagepub.chinau8.cn/tianhua_jinhua/Slice%201.png',
      ],
      jianguanList: [
        {
          name: "车企人18项资质审核",
          imgUrl: require("@/assets/product/jianguan1.png"),
        },
        {
          name: "系统准入",
          imgUrl: require("@/assets/product/jianguan2.png"),
        },
        {
          name: "生成唯一二维码",
          imgUrl: require("@/assets/product/jianguan3.png"),
        },
        {
          name: "校园旅游用车线上审批",
          imgUrl: require("@/assets/product/jianguan4.png"),
        },
        {
          name: "行程动态监管",
          imgUrl: require("@/assets/product/jianguan5.png"),
        },
      ],
      swiperOption: {
        loop: false,
        speed: 500,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        autoplay: false,
      }
    };
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    console.log('代码更新了')
  },
  methods: {
    showDialog() {
      this.$store.dispatch("SetDialog");
    }
  },
  beforeDestroy() {
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 1920px;
  height: 1080px;
  background: #fff;
  position: relative;

  .swiper-container {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /*蒙版颜色和透明度*/
      backdrop-filter: blur(3px);
      z-index: 10;
    }
  }

  .banner-img {
    width: 1920px;
    height: 1080px;
  }

  .title1 {
    width: 1300px;
    position: absolute;
    top: 330px;
    left: 180px;
    z-index: 999;
    font-size: 80px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 8px;
    line-height: 88px;
    text-align: left;
  }

  .title2 {
    width: 840px;
    position: absolute;
    top: 470px;
    left: 180px;
    z-index: 999;
    font-size: 32px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 3px;
    line-height: 48px;
    text-align: left;

  }

  .a-bounceinL {
    animation: bounceinL 2s ease-out backwards;
    animation-delay: 1s;
  }

  .a-bounceinR {
    animation: bounceinR 2s ease-out backwards;
    animation-delay: 4s;
  }


  .contact-button {
    position: absolute;
    top: 643px;
    left: 137px;
    z-index: 999;
    width: 160px;
    height: 54px;
    background: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    color: #217EFF;
    line-height: 23px;
    border: none;
  }
}


.name {
  height: 54px;
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  line-height: 42px;
  margin-bottom: 63px;
  text-align: center;
}

.jianguan {
  height: 789px;
  background: url("~@/assets/product/jianguan.png") no-repeat center center;
  background-size: 100% 100%;
  padding: 70px 225px 0px 225px;
  box-sizing: border-box;

  .desc {
    width: 100%;
    height: 120px;
    font-size: 28px;
    font-weight: 400;
    color: #333;
    line-height: 60px;
    text-align: left;
    margin-bottom: 100px;
  }

  .jianguan_row {
    display: flex;
    justify-content: space-between;
    margin-top: 115px;

    .jianguan_box {
      flex: 1;
      height: 36px;
      font-size: 24px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 98px;
        height: 110px;
        margin-bottom: 20px;
      }
    }
  }
}

.youba {
  height: 678px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/youba.png") no-repeat center center;
  background-size: 100% 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

.data {
  width: 1920px;
  height: 1000px;
  background: url("~@/assets/homepage/data-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 58px;
  box-sizing: border-box;

  .title {
    font-size: 42px;
    font-weight: 500;
    color: #000000;
    line-height: 49px;
  }

  img {

  }
}

</style>
