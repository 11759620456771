<template>
  <!--  <div class="cooperate-container">-->
  <!--    <div class="page1" @click="changePage">-->
  <!--      <template v-if="!pageChange">-->
  <!--        &lt;!&ndash;    <span :class="startAnimation ? 'a-fadeinL' : ''" v-if="startAnimation">测试动画</span>&ndash;&gt;-->
  <!--        <div-->
  <!--          class="text text1"-->
  <!--          :class="startAnimation ? 'a-fadeinL' : ''"-->
  <!--          v-if="startAnimation"-->
  <!--        >-->
  <!--          政府监管数据报表 执法全流程-->
  <!--        </div>-->
  <!--        <div-->
  <!--          class="text text2"-->
  <!--          :class="startAnimation ? 'a-fadeinL' : ''"-->
  <!--          v-if="startAnimation"-->
  <!--        >-->
  <!--          乘车安全实时监控 事后追溯<br />行业数据分析报表-->
  <!--        </div>-->
  <!--        <div-->
  <!--          class="text text3"-->
  <!--          :class="startAnimation ? 'a-fadeinR' : ''"-->
  <!--          v-if="startAnimation"-->
  <!--        >-->
  <!--          路面管理-->
  <!--        </div>-->
  <!--        <div-->
  <!--          class="text text4"-->
  <!--          :class="startAnimation ? 'a-fadeinR' : ''"-->
  <!--          v-if="startAnimation"-->
  <!--        >-->
  <!--          车辆应急调度<br />-->
  <!--          突发事件处理-->
  <!--        </div>-->
  <!--        <div-->
  <!--          class="text text5"-->
  <!--          :class="startAnimation ? 'a-fadeinR' : ''"-->
  <!--          v-if="startAnimation"-->
  <!--        >-->
  <!--          车辆安全性、合法性管理 车辆路线实时监控<br />-->
  <!--          行业数据分析报表-->
  <!--        </div>-->
  <!--      </template>-->
  <!--      <template v-if="pageChange">-->
  <!--        <img-->
  <!--          class="img"-->
  <!--          src="https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/cooperate-container-page2.png"-->
  <!--          alt=""-->
  <!--        />-->
  <!--      </template>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="container">
    <div class="content">
      <div class="title">多跨协同</div>
      <div class="text">
        五大部门信息互联互通，开放共享包车信息，依托道路运输监测监管系统，形成真实、准确、完整的包车客运公共数据集合。
        推动包车客运与旅游、教育地信息共享互通，促进运游育融合发展；完善包车客运应急事件处理调度；深化“数字打非”应用，
        加强部门协调，推进共享地方公安等部门道路卡口和视频信息， 运用数字化思维和手段，找准切入点和突破口，
        找到系统集成、多跨协同的解决方法和路径，有效查处包车非法经营问题。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll: 0,
      startAnimation: false, //文字是否出现
      pageChange: false, //点击切换页面效果
    };
  },
  methods: {
    changePage() {
      this.pageChange = !this.pageChange;
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
      this.startAnimation = this.scroll > 1350;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
//.cooperate-container {
//  width: 1920px;
//  height: 1080px;
//  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/cooperate-container-bg.png")
//    no-repeat;
//  box-sizing: border-box;
//  background-size: cover;
//  color: #333;
//  position: relative;
//
//  .page1 {
//    height: 100%;
//    cursor: pointer;
//  }
//
//  .img {
//    width: 100%;
//  }
//
//  .cooperate-head {
//    padding: 90px 240px 0;
//
//    .cooperate-title-icon {
//      margin-right: 10px;
//    }
//
//    .cooperate-title-text {
//      font-size: 36px;
//    }
//  }
//
//  .text {
//    border-radius: 12px 12px 12px 12px;
//    opacity: 1;
//    box-sizing: border-box;
//    font-size: 20px;
//    line-height: 31px;
//  }
//
//  .text1 {
//    width: 287px;
//    height: 95px;
//    padding: 19px 24px 14px 103px;
//    text-align: right;
//    position: absolute;
//    left: 210px;
//    top: 334px;
//    background: linear-gradient(
//      270deg,
//      rgba(4, 91, 248, 0.1) 0%,
//      rgba(4, 91, 248, 0) 100%
//    );
//  }
//
//  .text2 {
//    width: 287px;
//    height: 131px;
//    padding: 19px 24px 14px 103px;
//    text-align: right;
//    position: absolute;
//    left: 210px;
//    top: 656px;
//    background: linear-gradient(
//      270deg,
//      rgba(4, 91, 248, 0.1) 0%,
//      rgba(4, 91, 248, 0) 100%
//    );
//  }
//
//  .text3 {
//    width: 212px;
//    height: 57px;
//    padding: 14px 50px 12px 24px;
//    text-align: left;
//    position: absolute;
//    left: 1010px;
//    bottom: 205px;
//    background: linear-gradient(
//      90deg,
//      rgba(4, 91, 248, 0.1) 0%,
//      rgba(4, 91, 248, 0) 100%
//    );
//  }
//
//  .text4 {
//    width: 300px;
//    height: 94px;
//    padding: 16px 50px 16px 24px;
//    text-align: left;
//    position: absolute;
//    right: 223px;
//    bottom: 300px;
//    background: linear-gradient(
//      90deg,
//      rgba(4, 91, 248, 0.1) 0%,
//      rgba(4, 91, 248, 0) 100%
//    );
//  }
//
//  .text5 {
//    width: 300px;
//    height: 131px;
//    padding: 16px 50px 16px 24px;
//    text-align: left;
//    position: absolute;
//    top: 307px;
//    right: 210px;
//    background: linear-gradient(
//      90deg,
//      rgba(4, 91, 248, 0.1) 0%,
//      rgba(4, 91, 248, 0) 100%
//    );
//  }
//
//  .a-fadeinL {
//    animation: fadeinL 1.5s ease-out backwards;
//  }
//
//  .a-fadeinR {
//    animation: fadeinR 1.5s ease-out backwards;
//  }
//}
.container {
  width: 1920px;
  height: 566px;
  padding: 47px 160px;
  box-sizing: border-box;
  background: url("~@/assets/homepage/cooperate-bg.png") no-repeat;
  background-size: 100% 100%;

  .content {
    width: 1602px;
    height: 474px;
    background: rgba(194, 234, 255, 0.3);
    backdrop-filter: blur(20px);
    padding: 62px 96px;
    box-sizing: border-box;

    .title {
      font-size: 42px;
      font-weight: 500;
      color: #333333;
      line-height: 49px;
      margin-bottom: 53px;

      &::before {
        display: inline-block;
        content: '';
        width: 44px;
        height: 6px;
        background: #333333;
        position: relative;
        top: -10px;
        left: -20px;
      }

      &::after {
        display: inline-block;
        content: '';
        width: 44px;
        height: 6px;
        background: #333333;
        position: relative;
        top: -10px;
        right: -20px;
      }
    }

    .text {
      font-size: 26px;
      font-weight: 400;
      color: #333333;
      line-height: 60px;
      text-indent: 2em;
      text-align: left;
    }
  }
}
</style>
